import React from "react";  

const Intro = () => {
    return (
        <div className='bg-white w-full py-16 text-black px-4'>
            <div className='col-span-2'>
                <img src="" alt="" />
                <div className='mx-auto flex flex-col justify-center items-center'>
                    <h1 className='text-3xl py-6'>What is DAO Taiwan</h1>
                    <div className="sm:w-[80%]">
                        <p className='px-4 text-center'>Embark on a journey with DAO Taiwan, 
                        an elite club of cultural enthusiasts and explorers, 
                        passionately curating the 
                        finest experiences Taiwan has to offer.
                        </p>
                    </div>
                </div>  
            </div>
        </div>
    )
}

export default Intro;