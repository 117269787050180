import React from 'react'

const SiteMetadata = () => {
 return (
  <head>
   <meta charSet="utf-8" />
   <meta name="viewport" content="width=device-width, initial-scale=1" />
   <meta
     name="Wander Bot"
     content="Taiwan Unearthed Adventure Awaits"
   />
  </head>
 );
};

export default SiteMetadata;