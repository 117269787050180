import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Intro from './components/Intro';
import How from './components/How';
import Footer from './components/Footer';
import SiteMetadata from './components/SiteMatada';
import Newsletter from './components/Newsletter';
import CommunitySection from './components/CommunitySection';

function HomePage() {

  return (
    <div>
      <SiteMetadata />
      {/* <Navbar />
      If you want to display this text, you might consider putting it in a <p> or <h1> tag */}
      <Hero />
      <Intro />
      <How />
      <Newsletter />
      <CommunitySection />
      <Footer />
    </div>
  );
}

export default HomePage;
