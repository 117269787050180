import React from "react";

const How = () => {
    return (
        <div class="w-full bg-white items-center py-16 text-center">
            <div class=" text-blue-600 text-base py-2">HOW IT WORK</div>
            <div class=" text-black text-4xl">Wonder Bot at Work</div>
            <div className='max-w[1240px] mx-10 mt-10 grid md:grid-cols-3 gap-8'>
                <div className='w-full shadow-xl flex flex-col p-8 my-4 rounded-lg hover:scale-105 duration-300'>
                    <p className='text-5xl py-4'> 🔍
                    </p>
                    <h2 className='text-xl font-bold text-center py-6'>
                        Personalized Adventures
                    </h2>
                    <div className='text-center'>
                        <p className='py-3'>
                            Let Wonder Bot reveal Taiwan's hidden gems tailored just for you.
                        </p>
                    </div>
                </div>

                <div className='w-full shadow-xl flex flex-col p-8 my-4 rounded-lg hover:scale-105 duration-300'>
                    <p className='text-5xl py-4'> 🎁
                    </p>
                    <h2 className='text-xl font-bold text-center py-6'>
                        Rewards & Recognition
                    </h2>
                    <div className='text-center'>
                        <p className='py-3'>
                            Achieve, collect, and showcase with our gamified reward system.
                        </p>
                    </div>
                </div>

                <div className='w-full shadow-xl flex flex-col p-8 my-4 rounded-lg hover:scale-105 duration-300'>
                    <p className='text-5xl py-4'> 👝 
                    </p>
                    <h2 className='text-xl font-bold text-center py-6'>
                        Wallet Integration
                    </h2>
                    <div className='text-center'>
                        <p className='py-3'>
                            Connect and transact seamlessly with your online wallet.
                        </p>
                    </div>
                </div>
            </div>
                
        </div>
    )
}

export default How;