import React from 'react';
import Typed from 'react-typed';

const Hero = () => {
    const CTA_Button = [
        {href:"https://t.me/DaoTWBot", displayName:"Get Started", id: "Get Started"}
    ]

    return (
        <div className="text-white">
        <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
            <h1 className='md:text-5xl sm-text-4xl text-4xl font-bold md:py-6'>Taiwan Unearthed</h1>
            <div className='flex justify-center items-center'></div>
            <div>
                <p className='md:text-7xl sm:text-5xl text-xl font-bold py-4 gradient-text'>
                   Adventure Awaits
                </p>
            </div>
            <p className='md:text-xl text-xl text-gray-500 py-4'>
                Powered by Wonder Bot, Curated by DAO Taiwan.
            </p>
            {CTA_Button.map(item => 
                <button key={item.id} className='bg-[#0A76E9] w-[200px] rounded-md font-medium my-5 mx-auto py-3'>
                    <a href={item.href}>{item.displayName} </a>
                </button>
                )}
                
        </div>

        </div>
    )
}

export default Hero