import {
    FaFacebookSquare,
    FaInstagramSquare,
    FaTelegram,
    FaTwitterSquare,
} from 'react-icons/fa'

const Footer = () => {
    const linkList = [
        {href:"#Guide", displayName:"Guide", id: "Guide"},
        {href:"#Tutorial", displayName:"Tutorial", id: "Tutorial"},
        {href:"#Blog", displayName:"Blog", id: "Blog"}
    ]

    return ( 
        <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300' >
            <div>
                <h1 className='w-full text-xl font-bold text-[#00abdf]'>Wander Cloud</h1>
                <p className='py-4'>In Collaboration with WonderCloud. Redefining Exploration.</p>
                <div className='flex justify-between md:w-[75%] my-6'>
                    {/* <FaFacebookSquare size={30} />
                    <FaInstagramSquare size={30} />
                    <FaTwitterSquare size={30} />
                    <FaTelegram size={30} /> */}
                </div>
            </div>
            <div className='lg:col-span-2 flex justify-end'>
                    <ul className='w-[200px]'>
                        <li className='font-medium text-gray-400'>Docs</li>
                        {linkList.map(item=>
                            <li key={item.id} className='py-2 text-sm cursor-pointer'>
                                <a href={item.href}>{item.displayName}</a>
                            </li>
                        )}
                    </ul>
            
            
            </div>
        </div>
        
    )
}

export default Footer