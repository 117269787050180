import React from 'react';

const Newsletter = () => {
  return (
    <div className="flex flex-col items-center justify-center p-8 bg-gray-100 newsletter">
      <h1 className="text-3xl py-6">Sign Up for Newsletter</h1>
      <p className="text-gray-600 mb-8">For updates, exclusives, and the latest wonders</p>
      <form action="https://daotaiwan.us21.list-manage.com/subscribe/post?u=003854a19c55797f3b2e8a98a&amp;id=5b02a12ec7&amp;f_id=0070eee6f0" 
            method="post" 
            id="mc-embedded-subscribe-form" 
            name="mc-embedded-subscribe-form" 
            className="validate w-full max-w-lg mx-auto flex flex-col sm:flex-row items-center">
        <input type="email" name="EMAIL" id="mce-EMAIL" 
               className="w-full sm:flex-1 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 mr-4 mb-4 sm:mb-0"
               placeholder="Enter your email" 
               required />
        <input type="hidden" name="tags" value="2972320" />
        <input type="text" name="b_003854a19c55797f3b2e8a98a_5b02a12ec7" tabIndex="-1" className="hidden" />
        <button type="submit" name="subscribe" id="mc-embedded-subscribe" 
                className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">Subscribe</button>
      </form>
    </div>
  );
};

export default Newsletter;
