import React from 'react';
import { FaInstagram, FaTwitter, FaTelegramPlane } from 'react-icons/fa';

const CommunitySection = () => {
  return (
    <div className="flex flex-col items-center justify-center p-6 community-section">
      <h1 className="text-3xl py-6">Be Part Of Our Community</h1>
      <p className="text-gray-600 mb-6">For inquiries or collaboration opportunities.</p>
      <div className="flex flex-col items-center gap-10 md:flex-row mx-auto">
        <a href="https://www.instagram.com/DAOTaiwan" className="flex items-center justify-center border border-gray-300 bg-white text-sm rounded-lg p-3 shadow-md hover:scale-105 duration-300">
          <FaInstagram className="h-6 w-6 text-gray-700" />
          <span className="ml-2">DAOTaiwan</span>
        </a>
        <a href="https://www.twitter.com/DAOTaiwan" className="flex items-center justify-center border border-gray-300 bg-white text-sm rounded-lg p-3 shadow-md hover:scale-105 duration-300">
          <FaTwitter className="h-6 w-6 text-gray-700" />
          <span className="ml-2">DAOTaiwan</span>
        </a>
        <a href="https://t.me/DAOTaiwan" className="flex items-center justify-center border border-gray-300 bg-white text-sm rounded-lg p-3 shadow-md hover:scale-105 duration-300">
          <FaTelegramPlane className="h-6 w-6 text-gray-700" />
          <span className="ml-2">DAOTaiwan</span>
        </a>
      </div>
    </div>
  );
};

export default CommunitySection;
